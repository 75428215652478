<template>
	<div class="inviteBox">
		<!-- 用户投资 -->
		<div class="title">{{ $t("mining.t1") }}</div>
		<!-- 每份投资金额： -->
		<div class="maxJoin">{{ $t("mining.t2") }}100 USDT</div>
		<!-- 份数 -->
		<div class="amount">
			<span>{{ $t("mining.t3") }}</span>
			<div v-if="checkMining" class="mining_number">{{ $t("index.t31") }}<span class="num">{{ miningNum }}</span></div>
		</div>
		<div class="input_box">
			<van-field v-model="amount" type="digit"/>
			<!-- 最大 -->
			<div class="max" @click="max">{{ $t("mining.t4") }}</div>
		</div>
		
		<div class="input_reminder">
			<!-- 余额： -->
			<div>{{ $t("mining.t5") }}{{ $utils.handleAmount(usdtBalance, 4, 2) }} USDT</div>
		</div>
		
		<div class="btn_box">
			<!-- 取消 -->
			<van-button class="cancel_default_btn" @click="close" :disabled="confirmJoinLoading">{{ $t("mining.t6") }}</van-button>
			<!-- 确认 -->
			<van-button class="default_btn" @click="confirmJoin" :loading="confirmJoinLoading" :loading-text="confirmJoinLoadingTXT" :disabled="(miningNum==0 || amount>miningNum) && checkMining">{{ $t("mining.t7") }}</van-button>
		</div>
	</div>
</template>

<script>
import { Notify } from "vant-green";
import { ethers } from 'ethers';
export default {
	name: "MarketView",
	props: {},
	data() {
		return {
			superior: this.$store.state.superior,
			user_info: this.$store.state.user_info,
			miningNum:0,
			price: 100,
			amount: 1,
			usdtBalance: 0,
			confirmJoinLoading: false,
			confirmJoinLoadingTXT: this.$t("mining.t8"),	//提交中...
			checkMining: true,
			visitorIdByte: "",
		};
	},
	async mounted() {
		// this.$toast.loading()
		// const user = await this.$api.request("/get_user", {'account': localStorage.getItem("userAccount")},"POST");
		// this.checkMining = await new this.$MyContract().call("mine","checkMining",[]);
		// this.usdtBalance = await new this.$MyContract().call("usdt","balanceOf",[localStorage.getItem("userAccount")]);
		// this.miningNum = parseInt(await new this.$MyContract().call("mine","miningNum",[localStorage.getItem("userAccount")]));
		// this.visitorIdByte = await this.$signBytes(user.nonce);
		// this.$toast.clear();
		// // this.visitorIdByte = '0x8a767dbf59028e384c6e6a25f179d3b3fb051954a69e3055d8e3493632016148'
		
		try {
			this.$toast.loading()
			const user = await this.$api.request("/get_user", {'account': localStorage.getItem("userAccount")},"POST");
			console.log(user);
			console.log(user?.nonce)
			var task = [
				new this.$MyContract().call("mine","checkMining",[]),
				new this.$MyContract().call("usdt","balanceOf",[localStorage.getItem("userAccount")]),
				new this.$MyContract().call("mine","miningNum",[localStorage.getItem("userAccount")]),
				this.$signBytes(user?.nonce)
			];
			Promise.all(task).then(res => {
				this.checkMining = res[0];
				this.usdtBalance = ethers.BigNumber.from(res[1]);
				this.miningNum = parseInt(res[2]);
				this.visitorIdByte = res[3];
			}).catch(err => {
				throw new Error(err);
			})
		} catch (err) {
			console.log(err);
			Notify({ type: "danger", message: this.$t('methods.t24') });	//网络异常，请稍后重试
		} finally {
			setTimeout(() => {
				this.$toast.clear();
			}, 300);
		}
	},
	
	methods: {
		close(){
			this.$emit("close");
		},
		max(){
			console.log(this.usdtBalance);
			console.log(this.price);
			this.amount = parseInt(ethers.utils.formatEther((this.usdtBalance).toString(), 'ether')/this.price);
			if(this.amount>this.miningNum && this.checkMining){
				this.amount = this.miningNum;
			}
		},
		async confirmJoin(){
			if(this.miningNum<this.amount && this.checkMining){
				Notify({ type: "danger", message: this.$t("index.t31")+this.miningNum });	//您没有下级
				return;
			}
			//验证数量
			if(this.amount <= 0){
				Notify({ type: "danger", message: this.$t("mining.t9") });	//请输入正确的份数
				return;
			}
			
			try {
				this.confirmJoinLoading = true;
				if(this.checkMining){
					//校验签名
					const signRes = await this.$api.request("/checkSign", {
						account: localStorage.getItem("userAccount"),
						sign: this.visitorIdByte,
					},"POST");
					if(signRes['code'] != 200){
						Notify({ type: "danger", message: this.$t('mining.t18')+"\n\r["+ this.$utils.handleAddress(signRes['bindUser'],6,4) +"]" });	//指纹已使用
						throw new Error("指纹不可用");
					}
				}

				//判断余额是否足够
				const payAmount = ethers.utils.parseUnits((this.amount*this.price).toString(), 'ether');
				console.log("支付金额：",payAmount);
				const balance = await new this.$MyContract().call("usdt", "balanceOf",[localStorage.getItem("userAccount")]);
				if(ethers.BigNumber.from(balance).lt(payAmount)){
					Notify({ type: "danger", message: this.$t("mining.t12") });	//余额不足
					return;
				}
				// 检查授权
				var approve = await new this.$MyContract().call("usdt", "allowance", [
					localStorage.getItem("userAccount"), 
					this.$store.state.config.mine
				]);
				console.log("授权额度：", approve.toString());

				if (ethers.BigNumber.from(approve).lt(payAmount)) {
					this.confirmJoinLoadingTXT = this.$t("mining.t13")	//额度授权中...
					const setApprove = await new this.$MyContract().send("usdt", "approve", [
						this.$store.state.config.mine, 
						payAmount.toString()
					]);

					console.log(setApprove);
					if (setApprove && setApprove.hash) {
						Notify({ type: "success", message: this.$t("mining.t14") });	//授权成功
						approve = payAmount;
					}
				}

				if (ethers.BigNumber.from(approve).gte(payAmount)) {
					this.confirmJoinLoadingTXT = this.$t("mining.t15")	//合约执行中...
					const result = await new this.$MyContract().send("mine", "joinMining",[
						this.superior,
						this.amount.toString()
					]);
					console.log(result);
					if(result && result.hash){
						Notify({
							type: "success",
							message: this.$t("mining.t16"),	//投资成功
						})
						this.$store.state.changeJoin += 1;
						this.close();
					}
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.confirmJoinLoading = false;
			}
		},
	},
};
</script>

<style scoped lang="scss">

.inviteBox{
	height: fit-content;
	background-image: linear-gradient(0deg, 
		#262623 0%, 
		#33332f 100%);
	padding: 15px;
	.title{
		text-align: center;
		margin-top: 10px;
		margin-bottom: 30px;
		font-size: 20px;
		font-weight: bold;
		color: #fff;
	}
	.input_box{
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #1a1a18 !important;
		border-radius: 10px;
		.max{
			font-family: PingFang-SC-Bold;
			font-size: 14px;
			color: #1a1a18;
			background: #e0bf8f;
			border-radius: 5px;
			// padding: 5px 10px;
			width: 60px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			margin: 0 10px;
		}
	}
	.maxJoin{
		height: 44px;
		background-color: #383834;
		border-radius: 5px;
		font-family: PingFang-SC-Medium;
		font-size: 14px;
		color: #ffd33b;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}
	.input_reminder{
		display: flex;
		justify-content: flex-end;
		margin-top: 5px;
		font-family: PingFang-SC-Medium;
		font-size: 14px;
		color: #ffffff;
		text-align: right;
		.max{
			font-family: PingFang-SC-Bold;
			font-size: 14px;
			color: #e0bf8f;
		}
	}
	.amount{
		font-family: PingFang-SC-Medium;
		font-size: 14px;
		color: #ffffff;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
	}
	
	.btn_box{
		display: flex;
		gap: 20px;
		margin: 50px 0 10px;
	}
}

</style>
<style>
.inviteBox .van-field input{
	font-family: PingFang-SC-Medium;
	font-size: 15px !important;
	height: 44px !important;
	background: #1a1a18 !important;
	color: #e0bf8f !important;
	border-radius: 10px !important;
	padding: 0 10px !important;
	text-align: left !important;
}
</style>
