<template>
	<div class="content_box">
		<!-- 可用额度： -->
		<div class="fund">{{ $t('swap.t1') }}：{{ $utils.handleAmount(fund, 4, 2) }} U</div>
		<div class="box" style="margin-top: 20px">
			<div class="token_box">
				<div class="token_info">
					<div class="token">
						<img src="../assets/get.png" alt="" />
						<span>GBTC</span>
					</div>
					
					<div class="amount">
						<van-field class="input" v-model="gbtcAmount" type="number" placeholder="0.00" maxlength="14" :disabled="swapLoading" @input="changeAmount"/>
					</div>
				</div>
				<div class="balance">
					<!-- 余额： -->
					<div class="amount" >{{ $t('swap.t2') }}{{ $utils.handleAmount(gbtcBalance, 4, 2) }}</div>
					<div class="percent">
						<div @click="percent(25)">25%</div>
						<div @click="percent(50)">50%</div>
						<div @click="percent(75)">75%</div>
						<div @click="percent(100)">100%</div>
					</div>
				</div>
			</div>
		</div>
		<img class="change" src="../assets/change.png" alt="" />
		<div class="box">
			<div class="token_box">
				<div class="token_info">
					<div class="token">
						<img src="../assets/usdt.png" alt="" />
						<span>USDT</span>
					</div>
					<div class="amount">
						<span>{{ $utils.handleAmount(amountOut, 8, 2) }}</span>
						<van-loading size="18px" color="#9E9E9E" v-if="computeLoading"/>
					</div>
				</div>
			</div>
		</div>
		<div class="fee">1 GBTC ≈ {{ $utils.handleAmount(price, 8, 2) }} USDT</div>
		<van-button class="default_btn" style="margin-top: 30px" @click="swap" :disabled="gbtcAmount <= 0 || swapLoading" :loading="swapLoading" :loading-text="$t('swap.t7')">{{ $t('swap.t6') }}</van-button>
		<div class="rule">
			<!-- ① 卖出滑点：0.5%； -->
			<div>{{ $t('swap.t3') }}</div>
			<!-- ② 不支持通过swap买入GBTC，只能通过参与投资获得； -->
			<div>{{ $t('swap.t4') }}</div>
			<!-- ③ 不支持再外部进行买卖，请在本DAPP进行交易； -->
			<div>{{ $t('swap.t5') }}</div>
		</div>
	</div>
</template>

<script>
import { Notify } from "vant-green";
import { ethers } from "ethers";

export default {
	name: "MarketView",
	props: {},
	data() {
		return {
			gbtcBalance: 0,
			gbtcAmount: "",
			fund:0,
			price: 0,
			slippage: 5,	//最大滑点
			token1: "",
			token2: "",
			swapLoading: false,
			amountOut: 0,
			timer: null,
			timeSI: null,
			computeLoading: false
		};
	},
	watch: {
		"$store.state.tabRefIndex": function (newVal, oldVal) {
			this.timeSI && clearInterval(this.timeSI);
		},
	},
	async mounted() {
		//查询列表 
		this.gbtcBalance = this.$store.state.SwapView.gbtcBalance;
		this.price = this.$store.state.SwapView.price;
		this.fund = this.$store.state.SwapView.fund;

		//定时刷新单价
		if(this.$store.state.tabRefIndex==1){
			this.timeSI && clearInterval(this.timeSI);
			this.timeSI = setInterval(() => {
				this.getPrice();
			},5000);
		}
	},
	methods: {
		async onRefresh(callback) {
			await this.getData();
			setTimeout(() => {
				callback();
			}, 300);
		},
		async getData() {
			//查询gbtc余额
			this.getGbtcBalance();
			//查询单价
			this.getPrice();
			//查询可用资金
			this.getFund();
		},
		async getGbtcBalance() {
			try {
				this.gbtcBalance = await new this.$MyContract(this.$store.state.config.gbtcTokenAddress).call("erc20", "balanceOf", [
					localStorage.getItem("userAccount")
				]);
				//保存数据，下次进入直接读取
				this.$store.state.SwapView.gbtcBalance = this.gbtcBalance;
			}catch (error) {
				console.log(error);
			}
		},
		async getPrice() {
			try {
				//查询单价
				this.price = await new this.$MyContract().call("mine", "getAmountOut", [
					this.$store.state.config.gbtcTokenAddress, 
					this.$store.state.config.usdtTokenAddress, 
					Number(1e18).toString()
				]);
				//保存数据，下次进入直接读取
				this.$store.state.SwapView.price = this.price;
			}catch (error) {
				console.log(error);
			}
		},
		async getFund() {
			try {
				//查询单价
				const dappInfo = await new this.$MyContract().call("mine", "getDappInfo", [
					localStorage.getItem("userAccount")
				]);
				console.log(dappInfo)
				this.fund = dappInfo.releseFund;
				//保存数据，下次进入直接读取
				this.$store.state.SwapView.fund = this.fund;
			}catch (error) {
				console.log(error);
			}
		},
		percent(num) {
			this.gbtcAmount = this.$utils.toFixed(this.gbtcBalance * num / 100, 4);
			this.changeAmount();
		},
		changeAmount(){
			//防抖
			if(this.timer) {
				clearTimeout(this.timer);
			}
			this.amountOut = "";
			this.computeLoading = true;
			this.timer = setTimeout(() => {
				this.computeAmountOut();
			}, 1000);
		},
		async computeAmountOut() {
			var amountOut = 0;
			try {
				if(this.gbtcAmount && this.gbtcAmount > 0) {
					const amountIn = this.$utils.toWei(this.gbtcAmount*995/1000);
					console.log("amountIn:", amountIn);
					const path = [
						this.$store.state.config.gbtcTokenAddress, 
						this.$store.state.config.usdtTokenAddress
					];
					
					amountOut = await new this.$MyContract().call("mine", "getAmountOut", [path[0], path[1], amountIn]);
					console.log("amountOut:", amountOut);
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.computeLoading = false;
			}
			this.amountOut = amountOut.toString();
		},
		async swap() {
			// 将输入金额转换为最小单位（如Wei）
			const amountInSmallestUnit = this.$utils.toWei(this.gbtcAmount);
			// console.log("出售金额", amountInSmallestUnit.toString());

			try {
				this.swapLoading = true;
				//初始化合约
				const GbtcContract = new this.$MyContract(this.$store.state.config.gbtcTokenAddress);
				// 判断余额是否足够
				const balance = await GbtcContract.call("erc20", "balanceOf", [localStorage.getItem("userAccount")]);
				this.gbtcBalance = balance;
				this.$store.state.SwapView.gbtcBalance = balance;
				// console.log("余额：", balance.toString());
				if (ethers.BigNumber.from(balance).lt(amountInSmallestUnit)) {
					Notify({ type: "danger", message: this.$t("swap.t8") });	//余额不足
					throw new Error(this.$t("swap.t8"));		//余额不足
				}
				// 判断可用额度是否足够
				await this.getFund();
				if(this.fund == 0){
					Notify({ type: "danger", message: this.$t("swap.t10") });	//可用额度不足
					return
				}
				if (ethers.BigNumber.from(this.fund).lt(this.amountOut)) {
					// 等待用户确认是否继续兑换
					const message = this.$t("swap.t12", { value: this.$utils.handleAmount(this.fund, 4, 2) });
					const confirmed = await this.$dialog.confirm({
						title: this.$t("swap.t10"), // 提示
						message: message,
						confirmButtonText: this.$t("swap.t13"), // 继续兑换
						cancelButtonText: this.$t("swap.t11"), // 取消兑换
					}).then(() => true).catch(() => false);

					if (!confirmed) {
						// Notify({ type: "danger", message: this.$t("swap.t11") }); // 取消兑换
						return;
					}
				
					await this.sellGBTC(amountInSmallestUnit);
				} else {
					await this.sellGBTC(amountInSmallestUnit);
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.swapLoading = false;
			}
		},
		async sellGBTC(amountInSmallestUnit) {
			try {
				// 无需授权，直接兑换usdt
				const result = await new this.$MyContract().send("mine", "sellGBTC", [amountInSmallestUnit.toString(), this.slippage]);
				// console.log(result);
				if (result && result.hash) {
					Notify({ type: "success", message: this.$t("swap.t9") });	//兑换成功
					// 清空数据
					this.gbtcAmount = "";
					this.amountOut = "";
					await this.getData();
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.content_box {
	padding-bottom: 100px;
	.fund{
		margin-top: 40px;
		height: 44px;
		background-color: #00000040;
		border: 1px solid #e1995d;
		border-radius: 10px;
		font-family: PingFang-SC-Medium;
		font-size: 16px;
		color: #e0bf8f;
		line-height: 44px;
		text-align: center;
	}
	.box {
		background-color: #ffffff;
		border-radius: 10px;
		overflow: hidden;
		background-image: linear-gradient(90deg, #efd98d 0%, #e1995d 100%);
		opacity: 0.95;
	}
	
	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		font-weight: bold;
		border-bottom: solid 1px #e5e5e5;
		line-height: 1;
		padding-bottom: 14px;
		.info {
			width: 17px;
			height: 17px;
		}
	}
	.remark {
		margin-top: 12px;
		font-size: 12px;
		color: #666666;
		line-height: 18px;
	}
	.yellow {
		font-size: 12px;
		color: #f0b90b;
	}
	.token_box {
		.token_info {
			padding: 15px 20px;
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.token {
				display: flex;
				align-items: center;
				gap: 10px;
				flex: 1;
				font-size: 16px;
				color: #000000;
				img {
					width: 45px;
					height: 45px;
				}
			}
			.amount {
				border-left: solid 1px #fff;
				padding-left: 14px;
				font-size: 16px;
				flex: 1;
				color: #000;
				display: flex;
				gap: 5px;
			}
		}
		.balance {
			padding: 0 20px;
			font-size: 12px;
			color: #000;
			height: 93px;
			background-image: linear-gradient(0deg, #eacea1 0%, #d4aa77 100%);
			display: flex;
			flex-direction: column;
			gap: 15px;
			.amount{
				margin-top: 15px;
			}
			.percent {
				display: flex;
				align-items: center;
				gap:10px;
				>div{
					border-radius: 11px;
					border: solid 1px #1a1a18;
					width: 50px;
					height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
	.change {
		width: 32px;
		height: 32px;
		margin: -10px auto;
		display: flex;
		position: relative;
		z-index: 9;
	}
	.fee{
		margin-top: 15px;
		font-family: PingFang-SC-Medium;
		font-size: 12px;
		color: #b3aba1;
		text-align: center;
	}
	.rule{
		margin-top: 15px;
		font-family: PingFang-SC-Medium;
		font-size: 11px;
		line-height: 18px;
		color: #998d7d;
	}
}
</style>

<style>
.input input {
	font-family: PingFang-SC-Bold;
	font-size: 20px;
	color: #000000;
	height: 55px;
}
.input input::placeholder {
	color: #555;
}
.van-field__control:disabled {
	color: #555;
	-webkit-text-fill-color: #555;
}
</style>
