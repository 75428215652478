import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	"zh": {
		head: {
			t1: "链接钱包",
			t2: "钱包地址复制成功",
			t3: "断开链接",
			t4: "是否断开钱包链接？",
			t5: "取消",
			t6: "确认",
		},
		home: {
			t1: "下拉即可刷新...",
			t2: "释放即可刷新...",
			t3: "加载中...",
			t4: "挖矿",
			t5: "交易",
			t5_1:"聊天",
			t6: "算力挖矿明细",
			t7: "算力",
			t8: "GBTC数量",
			t9: "日期",
			t10: "暂无数据",
			t11: "知道了",
			t12: "团队奖励明细",
			t13: "类型",
			t14: "数量",
			t15: "日期",
			t16: "太阳线",
			t17: "二二滑落",
			t18: "GBTC币",
			t19: "① 发行总量1000万枚。",
			t20: "② 参与投资后每24小时释放一次奖励。",
			t21: "③ GBTC币卖税0.5%，仅通过挖矿获得。",
			t22: "合约地址：",
			t23: "日期",
			t24: "剩余",
			t25: "状态",
			t26: "顺序",
			t27: "已释放",
			t28: "待释放",
			t29: "提前释放",
			t30: "第{day}天",
			t31: "玩法说明"
		},
		rule: [
			"单次最低投资100USDT，最大获取1.5倍收益，单设备每人初始最大投资份额10次共1,000U，每推荐一人投资+1次投资份额",
			"投资获得1.5倍算力和1.5倍卖币额度，每日按照金本位3%释放，共计50天释放完毕",
			"收益组成部分：静态收益+直推奖+二二滑落网体奖励",
			"静态收益：每日释放的算力，点击领取后会从底池自动帮您兑换成等值的GBTC代币，您可在DAPP中直接出售或转入代币合约自动出售",
			"直推奖：您推荐的直属下级投资或复投金额的20%将从底池兑换成等值数量的GBTC代币",
			"二二滑落网体奖励：用户投资或复投时，依次从投资人开始向上寻找二二滑落网体的有效上级，形成一个最大20人名单均分投资金额的20%USDT直接转到钱包账户",
			"有效上级说明：投资人在您以下20层内且未超出您的有效范围（有效范围=12层内无需推荐，每直推一人投资+1层）并且您的算力和总可用额度充足",
			"团队奖励和直推奖励将从释放计划尾部依次抵扣",
			"如您的算力已释放或抵扣完毕，奖励将会于购买GBTC销毁(烧伤)",
			"出售代币时将扣除卖币额度",
			"二二滑落网体奖励将扣除卖币额度，如未释放则从释放计划尾部依次抵扣",
		],
		index: {
			t1: "我的邀请人",
			t2: "邀请链接",
			t3: "复制邀请链接",
			t4: "换绑邀请人",
			t5: "首次投资成功后，邀请人不可更换",
			t6: "余额",
			t7: "参与挖矿",
			t8: "产矿倒计时",
			t9: "待释放算力",
			t9_1: "待释放额度",
			t10: "能量",
			t11: "可领取",
			t12: "价值",
			t13: "① 每24小时可领取一次；",
			t14: "② 未领取的GBTC可累积领取；",
			t15: "③ 领取奖励扣除对应的算力。",
			t16: "领取",
			t17: "团队奖励",
			t18: "已获取",
			t19: "① 团队奖励自动到账；",
			t20: "② 团队奖励扣除对应的算力；",
			t21: "③ 用户投资时40%的U将奖励给团队。",
			t22: "直推",
			t23: "团队",
			t24: "直推账号",
			t25: "人数",
			t26: "暂无数据",
			t27: "团队账号",
			t28: "人数",
			t29: "领取成功",
			t30: "最大算力(每日释放2%)",
			t31: "可购矿机：",
			t32: "每日",
			t33: "释放奖励",
			t34: "无释放计划",
		},
		bind:{
			t1: "我的推荐人",
			t2: "绑定并升级后无法修改，请核对地址是否正确",
			t3: "取消",
			t4: "确认",
			t5: "提交中...",
			t6: "请输入正确的地址",
			t7: "不能绑定自己",
			t8: "您填写的上级不存在",
		},
		mining: {
			t1: "用户投资",
			t2: "每份投资金额：",
			t3: "份数",
			t4: "最大",
			t5: "余额：",
			t6: "取消",
			t7: "确认",
			t8: "提交中...",
			t9: "请输入正确的份数",
			t10: "输入的份额不足",
			t11: "上级地址错误，请重新绑定",
			t12: "余额不足",
			t13: "额度授权中...",
			t14: "授权成功",
			t15: "合约执行中...",
			t16: "投资成功",
			t17: "指纹签名不可用",
			t18: "单个设备仅可绑定一个账户",
		},
		swap: {
			t1: "可用额度",
			t2: "余额：",
			t3: "① 卖出滑点：0.5%；",
			t4: "② 不支持通过swap买入GBTC，只能通过参与投资获得；",
			t5: "③ 不支持再外部进行买卖，请在本DAPP进行交易；",
			t6: "兑 换",
			t7: "合约执行中...",
			t8: "余额不足",
			t9: "兑换成功",
			t10: "可用额度不足",
			t11: "取消兑换",
			t12: "您的可用资金还剩 {value} USDT，系统将自动按照最大价值进行兑换。是否继续兑换？",
			t13: "继续兑换",
		},
		methods: {
			t0: "未检测到钱包，请刷新页面后再试！",
			t16: '用户拒绝了交易',
			t17: '调用合约时发生错误',
			t18: '账户余额不足，无法完成交易',
			t19: '授权额度不足，请增加授权额度',
			t20: '提供的交易金额不足，无法完成交易',
			t21: 'Gas不足，合约无法执行',
			t22: '无法估算 Gas 限制，请手动设置 gas limit',
			t23: '网络连接异常，请检查您的网络连接',
			t24: '请求超时，请稍后重试',
			t25: '交易已过期，请重试',
			t26: '交易的 gas 价格过低，请提高 gas 价格',
			t27: '交易已被替换',
			t28: '交易耗尽 Gas，请增加 Gas 限制',
			t29: '签名无效，请检查签名参数',
			t30: '交易失败'
		},
	},
	"zh-hant": {
		"head": {
			"t1": "鏈接錢包",
			"t2": "錢包地址複製成功",
			"t3": "斷開鏈接",
			"t4": "是否斷開錢包鏈接？",
			"t5": "取消",
			"t6": "確認"
		},
		"home": {
			"t1": "下拉即可刷新...",
			"t2": "釋放即可刷新...",
			"t3": "加載中...",
			"t4": "挖礦",
			"t5": "交易",
			"t5_1":"聊天",
			"t6": "算力挖礦明細",
			"t7": "算力",
			"t8": "GBTC數量",
			"t9": "日期",
			"t10": "暫無數據",
			"t11": "知道了",
			"t12": "團隊獎勵明細",
			"t13": "類型",
			"t14": "數量",
			"t15": "日期",
			"t16": "太陽線",
			"t17": "二二滑落",
			"t18": "GBTC幣",
			"t19": "① 發行總量1000萬枚。",
			"t20": "② 參與投資後每24小時釋放一次獎勵。",
			"t21": "③ GBTC幣賣稅0.5%， 僅通過挖礦獲得。",
			"t22": "合約地址：",
			"t23": "日期",
			"t24": "剩餘",
			"t25": "狀態",
			"t26": "順序",
			"t27": "已釋放",
			"t28": "待釋放",
			"t29": "提前釋放",
			"t30": "第{day}天",
			"t31": "玩法說明",
		},
		"rule": [
			"單次最低投資100USDT，最大獲取1.5倍收益，單設備每人初始最大投資份額10次共1,000U，每推薦一人投資+1次投資份額",
			"投資獲得1.5倍算力和1.5倍賣幣額度，每日按照金本位3%釋放，共計50天釋放完畢",
			"收益組成部分：靜態收益+直推獎+二二滑落網體獎勵",
			"靜態收益：每日釋放的算力，點擊領取後會從底池自動幫您兌換成等值的GBTC代幣，您可在DAPP中直接出售或轉入代幣合約自動出售",
			"直推獎：您推薦的直屬下級投資或複投金額的20%將從底池兌換成等值數量的GBTC代幣",
			"二二滑落網體獎勵：用戶投資或複投時，依次從投資人開始向上尋找二二滑落網體的有效上級，形成一個最大20人名單均分投資金額的20%USDT直接轉到錢包賬戶",
			"有效上級說明：投資人在您以下20層內且未超出您的有效範圍（有效範圍=初始12層，每直推一人投資+1層）並且您的算力和總可用額度充足",
			"團隊獎勵和直推獎勵將從釋放計劃尾部依次抵扣",
			"如您的算力已釋放或抵扣完畢，獎勵將會於購買GBTC銷毀（燒傷）",
			"出售代幣時將扣除賣幣額度",
			"二二滑落網體獎勵將扣除賣幣額度，如未釋放則從釋放計劃尾部依次抵扣"
		],
		"index": {
			"t1": "我的邀請人",
			"t2": "邀請鏈接",
			"t3": "複製邀請鏈接",
			"t4": "換綁邀請人",
			"t5": "首次投資成功後，邀請人不可更換",
			"t6": "餘額",
			"t7": "參與挖礦",
			"t8": "產礦倒計時",
			"t9": "待釋放算力",
			"t9_1": "待釋放額度",
			"t10": "能量",
			"t11": "可領取",
			"t12": "價值",
			"t13": "① 每24小時可領取一次；",
			"t14": "② 未領取的GBTC可累積領取；",
			"t15": "③ 領取獎勵扣除對應的算力。",
			"t16": "領取",
			"t17": "團隊獎勵",
			"t18": "已獲取",
			"t19": "① 團隊獎勵自動到賬；",
			"t20": "② 團隊獎勵扣除對應的算力；",
			"t21": "③ 用戶投資時40%的U將獎勵給團隊。",
			"t22": "直推",
			"t23": "團隊",
			"t24": "直推賬號",
			"t25": "人數",
			"t26": "暫無數據",
			"t27": "團隊賬號",
			"t28": "人數",
			"t29": "領取成功",
			"t30": "最大算力(每日釋放2%)",
			"t31": "可購礦機：",
			t32: "每日",
			t33: "釋放獎勵",
			t34: "無釋放計畫",
		},
		"bind": {
			"t1": "我的推薦人",
			"t2": "綁定並升級後無法修改，請核對地址是否正確",
			"t3": "取消",
			"t4": "確認",
			"t5": "提交中...",
			"t6": "請輸入正確的地址",
			"t7": "不能綁定自己",
			"t8": "您填寫的上級不存在"
		},
		"mining": {
			"t1": "用戶投資",
			"t2": "每份投資金額：",
			"t3": "份數",
			"t4": "最大",
			"t5": "餘額：",
			"t6": "取消",
			"t7": "確認",
			"t8": "提交中...",
			"t9": "請輸入正確的份數",
			"t10": "輸入的份額不足",
			"t11": "上級地址錯誤，請重新綁定",
			"t12": "餘額不足",
			"t13": "額度授權中...",
			"t14": "授權成功",
			"t15": "合約執行中...",
			"t16": "投資成功",
			"t17": "指纹簽名不可用",
			"t18": "每個裝置僅能綁定一個賬戶",
		},
		"swap": {
			"t1": "可用額度",
			"t2": "餘額：",
			"t3": "① 賣出滑點：0.5%；",
			"t4": "② 不支持通過swap買入GBTC，只能通過參與投資獲得；",
			"t5": "③ 不支持在外部進行買賣，請在本DAPP進行交易；",
			"t6": "兌 換",
			"t7": "合約執行中...",
			"t8": "餘額不足",
			"t9": "兌換成功",
			"t10": "可用額度不足",
			"t11": "取消兌換",
			"t12": "您的可用资金还剩 {value} USDT，系统将自动按照最大价值进行兑换。是否继续兑换？",
			"t13": "繼續兌換",
		},
		"methods": {
			"t0": "未檢測到錢包，請刷新頁面後再試！",
			"t16": "用戶拒絕了交易",
			"t17": "調用合約時發生錯誤",
			"t18": "賬戶餘額不足，無法完成交易",
			"t19": "授權額度不足，請增加授權額度",
			"t20": "提供的交易金額不足，無法完成交易",
			"t21": "Gas不足，合約無法執行",
			"t22": "無法估算 Gas 限制，請手動設置 gas limit",
			"t23": "網絡連接異常，請檢查您的網絡連接",
			"t24": "請求超時，請稍後重試",
			"t25": "交易已過期，請重試",
			"t26": "交易的 gas 價格過低，請提高 gas 價格",
			"t27": "交易已被替換",
			"t28": "交易耗盡 Gas，請增加 Gas 限制",
			"t29": "簽名無效，請檢查簽名參數",
			"t30": "交易失敗"
		}
	},
	"en": {
		"head": {
			"t1": "Connect Wallet",
			"t2": "Wallet address copied successfully",
			"t3": "Disconnect",
			"t4": "Do you want to disconnect the wallet?",
			"t5": "Cancel",
			"t6": "Confirm"
		},
		"home": {
			"t1": "Pull down to refresh...",
			"t2": "Release to refresh...",
			"t3": "Loading...",
			"t4": "Mining",
			"t5": "Trade",
			"t5_1":"Chat",
			"t6": "Mining Details",
			"t7": "Hashrate",
			"t8": "GBTC Amount",
			"t9": "Date",
			"t10": "No data available",
			"t11": "Got it",
			"t12": "Team Rewards Details",
			"t13": "Type",
			"t14": "Amount",
			"t15": "Date",
			"t16": "Sun Line",
			"t17": "Binary Drop",
			"t18": "GBTC Coin",
			"t19": "① Total supply: 10 million GBTC;",
			"t20": "② Rewards are released every 24 hours after investment.",
			"t21": "③ Selling tax: 0.5%, Obtained solely through mining.",
			"t22": "Contract Address:",
			"t23": "Date",
			"t24": "Remaining",
			"t25": "Status",
			"t26": "Order",
			"t27": "Released",
			"t28": "Pending Release",
			"t29": "Early Release",
			"t30": "Day {day}",
			"t31": "Gameplay"
		},
		"rule": [
			"The minimum investment per transaction is 100 USDT, with a maximum return of 1.5 times. Each person can initially invest up to 10 times per device, totaling 1,000U. For every person you refer who invests, your investment quota increases by 1 time.",
			"Investments earn 1.5x computing power and 1.5x token selling quota, released daily at 3% of the gold standard, completed in 50 days.",
			"Income components: Static income + Direct referral bonus + 2x2 spillover network reward.",
			"Static income: The daily released computing power can be claimed and will be automatically exchanged into equivalent GBTC tokens from the pool. These tokens can be sold directly in the DAPP or transferred to the token contract for automatic sale.",
			"Direct referral bonus: 20% of the investment or reinvestment amount of your direct referrals will be exchanged into an equivalent amount of GBTC tokens from the pool.",
			"2x2 spillover network reward: When users invest or reinvest, the system sequentially searches upward from the investor for valid uplines in the 2x2 spillover network, forming a maximum 20-person list to equally share 20% of the investment amount in USDT directly transferred to wallet accounts.",
			"Definition of valid uplines: Investors within 20 levels below you and within your valid range (valid range = initial 12 levels, +1 level for every person you directly refer who invests), and with sufficient computing power and total available quota.",
			"Team rewards and direct referral rewards will be deducted sequentially from the end of the release plan.",
			"If your computing power is fully released or deducted, the rewards will be burned (destroyed) upon purchasing GBTC.",
			"Selling tokens will deduct the selling quota.",
			"2x2 spillover network rewards will deduct the selling quota. If not released, they will be deducted sequentially from the end of the release plan."
		],
		"index": {
			"t1": "My Referrer",
			"t2": "Referral Link",
			"t3": "Copy Referral Link",
			"t4": "Change Referrer",
			"t5": "Referrer cannot be changed after first successful investment",
			"t6": "Balance",
			"t7": "Join Mining",
			"t8": "Mining Countdown",
			"t9": "waiting Release Power",
			"t9_1": "Pending release limit",
			"t10": "Energy",
			"t11": "Claimable",
			"t12": "Value",
			"t13": "① Can be claimed once every 24 hours;",
			"t14": "② Unclaimed GBTC can be accumulated;",
			"t15": "③ Claiming rewards deducts corresponding hashrate.",
			"t16": "Claim",
			"t17": "Team Rewards",
			"t18": "Obtained",
			"t19": "① Team rewards are credited automatically;",
			"t20": "② Team rewards deduct hashrate;",
			"t21": "③ 40% of investment amount is rewarded to the team.",
			"t22": "Direct Referral",
			"t23": "Team",
			"t24": "Direct Referrals",
			"t25": "Number of People",
			"t26": "No data available",
			"t27": "Team Accounts",
			"t28": "Number of People",
			"t29": "Claimed successfully",
			"t30": "Max Power(2% daily)",
			"t31": "Mining frequency: ",
			t32: "Daily",
			t33: "Release reward",
			t34: "No release plan",
		},
		"bind": {
			"t1": "My Referrer",
			"t2": "Cannot modify after binding and upgrading. Please verify the address.",
			"t3": "Cancel",
			"t4": "Confirm",
			"t5": "Submitting...",
			"t6": "Please enter a valid address",
			"t7": "Cannot bind yourself",
			"t8": "Referrer does not exist"
		},
		"mining": {
			"t1": "User Investment",
			"t2": "Investment amount per share:",
			"t3": "Shares",
			"t4": "Max",
			"t5": "Balance:",
			"t6": "Cancel",
			"t7": "Confirm",
			"t8": "Submitting...",
			"t9": "Please enter a valid number of shares",
			"t10": "Insufficient shares",
			"t11": "Invalid referrer address, please rebind",
			"t12": "Insufficient balance",
			"t13": "Granting authorization...",
			"t14": "Authorization successful",
			"t15": "Executing contract...",
			"t16": "Investment successful",
			"t17": "Authorization failed",
			"t18": "Each device can only bind one account",
		},
		"swap": {
			"t1": "Available Quota",
			"t2": "Balance:",
			"t3": "① Selling Slippage: 0.5%;",
			"t4": "② GBTC cannot be purchased via swap, only through investment;",
			"t5": "③ External buying and selling is not supported. Please trade within this DAPP.",
			"t6": "Swap",
			"t7": "Executing contract...",
			"t8": "Insufficient balance",
			"t9": "Swap successful",
			"t10": "Insufficient available credit limit",
			"t11": "Cancel swap",
			"t12": "Your available funds still have {value} USDT, and the system will automatically exchange them according to the maximum value. Do you want to continue exchanging?",
			"t13": "Confirm swap",
		},
		"methods": {
			"t0": "Wallet not detected. Please refresh and try again.",
			"t16": "User rejected the transaction",
			"t17": "Error during contract call",
			"t18": "Insufficient balance for transaction",
			"t19": "Insufficient authorization. Please increase authorization.",
			"t20": "Transaction amount insufficient",
			"t21": "Unable to estimate gas consumption; contract execution might fail",
			"t22": "Cannot estimate gas limit; please set manually",
			"t23": "Network connection error. Please check your connection",
			"t24": "Request timed out, please try again",
			"t25": "Transaction expired, please retry",
			"t26": "Gas price too low, please increase",
			"t27": "Transaction has been replaced",
			"t28": "Transaction ran out of gas, increase limit",
			"t29": "Invalid signature, check parameters",
			"t30": "Transaction failed"
		},
	},
	"ja": {
		"head": {
			"t1": "ウォレットを接続",
			"t2": "ウォレットアドレスのコピーが成功しました",
			"t3": "接続を切断",
			"t4": "ウォレットの接続を切断しますか？",
			"t5": "キャンセル",
			"t6": "確認"
		},
		"home": {
			"t1": "下に引っ張って更新...",
			"t2": "離して更新...",
			"t3": "読み込み中...",
			"t4": "マイニング",
			"t5": "取引",
			"t5_1":"チャット",
			"t6": "マイニング明細",
			"t7": "ハッシュレート",
			"t8": "GBTC量",
			"t9": "日付",
			"t10": "データなし",
			"t11": "わかりました",
			"t12": "チーム報酬明細",
			"t13": "タイプ",
			"t14": "量",
			"t15": "日付",
			"t16": "サンライン",
			"t17": "二二ドロップ",
			"t18": "GBTCコイン",
			"t19": "① 総発行量1000万GBTC；",
			"t20": "② 投資参加後24時間ごとにボーナスを放出。",
			"t21": "③ GBTCの売上税は0.5％、掘削のみで取得。",
			"t22": "契約アドレス：",
			"t23": "日付",
			"t24": "残り",
			"t25": "ステータス",
			"t26": "順序",
			"t27": "リリース済み",
			"t28": "リリース待ち",
			"t29": "早期リリース",
			"t30": "{day}日目",
			"t31": "遊び方"
		},
		"rule": [
			"1回の最低投資額は100USDTで、最大1.5倍の利益を得られます。1デバイスあたり最初は最大10回の投資枠があり、合計1,000Uです。他の人を1人推薦して投資させるごとに、投資枠が1回分追加されます。",
			"投資により1.5倍の算力と1.5倍のトークン売却枠を取得できます。毎日3%の金本位に基づいてリリースされ、合計50日で完了します。",
			"収益の構成要素：静的収益＋直接推薦ボーナス＋2×2スピルオーバーネットワーク報酬。",
			"静的収益：毎日リリースされる算力は、クリックして受け取ると、プールから同等のGBTCトークンに自動的に交換されます。このトークンは、DAPP内で直接販売するか、トークン契約に転送して自動販売することができます。",
			"直接推薦ボーナス：あなたが推薦した直属の下位者の投資または再投資額の20%が、プールから同等のGBTCトークンに交換されます。",
			"2×2スピルオーバーネットワーク報酬：ユーザーが投資または再投資する際、投資者から順次上位者を検索し、2×2スピルオーバーネットワークの有効な上位者を見つけ、最大20人のリストを形成し、投資額の20%USDTを均等に分配してウォレットアカウントに直接転送します。",
			"有効な上位者の定義：あなたの下位20層以内におり、あなたの有効範囲内にある（有効範囲＝初期12層、直接推薦1人ごとに1層追加）とともに、十分な算力と総利用可能枠がある。",
			"チーム報酬と直接推薦ボーナスは、リリース計画の最後から順に差し引かれます。",
			"あなたの算力がすべてリリースされるか差し引かれると、報酬はGBTC購入時にバーン（焼却）されます。",
			"トークンを販売する際には、売却枠が差し引かれます。",
			"2×2スピルオーバーネットワーク報酬は、売却枠が差し引かれます。リリースされていない場合は、リリース計画の最後から順に差し引かれます。"
		],
		"index": {
			"t1": "私の紹介者",
			"t2": "紹介リンク",
			"t3": "紹介リンクをコピー",
			"t4": "紹介者の再バインド",
			"t5": "最初の投資が成功後、紹介者は変更できません",
			"t6": "残高",
			"t7": "掘削に関与する",
			"t8": "産鉱カウントダウン",
			"t9": "解放待ちの計算力",
			"t9_1": "リリース保留中の限度",
			"t10": "エネルギー",
			"t11": "請求可能",
			"t12": "価値",
			"t13": "① 24時間ごとに1回請求可能；",
			"t14": "② 未請求のGBTCは蓄積可能；",
			"t15": "③ インセンティブ控除の対応する計算力。",
			"t16": "請求",
			"t17": "チーム報酬",
			"t18": "獲得済み",
			"t19": "① チーム報酬は自動的に入金；",
			"t20": "② チーム奨励金控除対応の計算力；",
			"t21": "③ 投資時の40％のUがチームに報酬として分配。",
			"t22": "直接紹介",
			"t23": "チーム",
			"t24": "直接紹介アカウント",
			"t25": "人数",
			"t26": "データなし",
			"t27": "チームアカウント",
			"t28": "人数",
			"t29": "請求が成功しました",
			"t30": "最大計算力（1日2%）",
			"t31": "購入可能な鉱機：",
			t32: "毎日",
			t33: "報酬を解放",
			t34: "リリース計画はありません",
		},
		"bind": {
			"t1": "私の推薦者",
			"t2": "バインドおよびアップグレード後に変更不可、住所の確認を行ってください",
			"t3": "キャンセル",
			"t4": "確認",
			"t5": "送信中...",
			"t6": "有効なアドレスを入力してください",
			"t7": "自分自身をバインドできません",
			"t8": "上位が存在しません"
		},
		"mining": {
			"t1": "ユーザーの投資",
			"t2": "1シェア当たりの投資額：",
			"t3": "シェア数",
			"t4": "最大",
			"t5": "残高：",
			"t6": "キャンセル",
			"t7": "確認",
			"t8": "送信中...",
			"t9": "正しいシェア数を入力してください",
			"t10": "入力されたシェア数が不足",
			"t11": "上位アドレスが誤っています。再バインドしてください",
			"t12": "残高不足",
			"t13": "認可中...",
			"t14": "認可成功",
			"t15": "契約を実行中...",
			"t16": "投資成功",
			"t17": "認可失敗",
			"t18": "各アプライアンスは1つのアカウントだけをバインドできます"
		},
		"swap": {
			"t1": "利用可能額",
			"t2": "残高：",
			"t3": "① 売却スリッページ：0.5％；",
			"t4": "② GBTCはswapで購入不可、投資で獲得可能；",
			"t5": "③ 外部での売買をサポートしていません。本DAPPで取引してください。",
			"t6": "交換",
			"t7": "契約を実行中...",
			"t8": "残高不足",
			"t9": "交換成功",
			"t10": "利用可能額不足",
			"t11": "交換をキャンセル",
			"t12": "利用可能な資金にはまだ{value} USDTが残っており、システムは自動的に最大価値に基づいて両替されます。両替を続行しますか？",
			"t13": "換算の再開",
		},
		"methods": {
			"t0": "ウォレットが検出されませんでした。ページをリフレッシュして再試行してください。",
			"t16": "ユーザーがトランザクションを拒否しました",
			"t17": "契約呼び出し時にエラーが発生しました",
			"t18": "トランザクションに対する残高が不足しています",
			"t19": "許可が不足しています。許可を増やしてください。",
			"t20": "取引金額が不足しています",
			"t21": "ガス消費量を予測できません。契約実行が失敗する可能性があります",
			"t22": "ガス制限を予測できません。手動で設定してください",
			"t23": "ネットワーク接続エラー。接続を確認してください",
			"t24": "リクエストのタイムアウト、再試行してください",
			"t25": "トランザクションが期限切れになりました、再試行してください",
			"t26": "ガス価格が低すぎます。価格を上げてください",
			"t27": "トランザクションが置き換えられました",
			"t28": "トランザクションがガス切れになりました。ガス制限を増やしてください",
			"t29": "署名が無効です。パラメータを確認してください",
			"t30": "トランザクションが失敗しました"
		}
	}
};

const i18n = new VueI18n({
	locale: 'zh-hant', // 默认语言
	fallbackLocale: 'zh-hant', // 未找到翻译时的默认语言
	messages
});

export default i18n;
