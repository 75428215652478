<template>
	<div class="container" :style="`padding-top: ${active==2?'0':'50px'}`">
		<img src="../assets/banner.png" alt="" class="banner_bj" />
		<!-- 页面内容切换 -->
		<van-pull-refresh v-model="refreshLoading" @refresh="onRefresh" :pulling-text="$t('home.t1')" :loosing-text="$t('home.t2')" :loading-text="$t('home.t3')">
			<div class="content home">
				<van-tabs v-model="active" @change="onChangeTabBar" animated swipeable offset-top="50px" class="my_tabs" line-height="0" title-inactive-color="#000" title-active-color="#fff" background="#fff">
					<van-tab>
						<Index :key="keys[0]" ref="index" @bindSuperior="showBindSuperior = true" @joinMining="showJoinMining = true" @shoeGBTCInfo="show_gbtc_info = true" @showRewardRecord="getRewardRecord" @showTeamRewardRecord="getTeamRewardRecord" @releasePower="showReleasePlan(1)" @releaseFund="showReleasePlan(2)" @rule="showRule=true"/>
					</van-tab>
					<van-tab>
						<Swap :key="keys[1]" ref="swap" />
					</van-tab>
					<van-tab>
						<iframe 
							style="width: 100%; height: calc(100vh - 50px)"
							:key="keys[2]"
							src="https://im.weget.pro" 
							frameborder="0" 
							class="iframe-content"
						></iframe>
					</van-tab>
				</van-tabs>
			</div>
		</van-pull-refresh>
		<!-- 底部导航 -->
		<van-tabbar v-model="active" @change="onChangeTabBar" active-color="#f0b90b" inactive-color="#999999">
			<van-tabbar-item>
				<!-- 私募 -->
				<span>{{ $t("home.t4") }}</span>
				<template #icon="props">
					<img :src="`/static/tabbar/home${props.active ? '_active' : ''}.png`" />
				</template>
			</van-tabbar-item>

			<van-tabbar-item>
				<!-- Swap -->
				<span>{{ $t("home.t5") }}</span>
				<template #icon="props">
					<img :src="`/static/tabbar/swap${props.active ? '_active' : ''}.png`" />
				</template>
			</van-tabbar-item>

			<van-tabbar-item>
				<!-- Chat -->
				<span>{{ $t("home.t5_1") }}</span>
				<template #icon="props">
					<img :src="`/static/tabbar/chat${props.active ? '_active' : ''}.png`" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
		<!-- 绑定上级 -->
		<van-popup v-model="showBindSuperior" round style="width: calc(100% - 30px); background: transparent" :close-on-click-overlay="false">
			<bindSuperior @close="showBindSuperior = false" />
		</van-popup>
		<!-- 参与投資 -->
		<van-popup v-model="showJoinMining" round style="width: calc(100% - 30px); background: transparent" :close-on-click-overlay="false">
			<JoinMining v-if="showJoinMining" @close="showJoinMining = false" />
		</van-popup>
		<!-- 算力挖矿明细 -->
		<van-dialog v-model="show_reward_record" width="88%" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_table_box">
				<!-- 算力挖矿明细 -->
				<div class="title">{{ $t("home.t6") }}</div>
				<div class="subtotal">
					<div class="th">
						<div>
							<!-- 算力 -->
							<span>{{ $t("home.t7") }}(USDT)</span>
						</div>
						<div>
							<!-- GBTC数量 -->
							<span>{{ $t("home.t8") }}</span>
						</div>
						<div>
							<!-- 日期 -->
							<span>{{ $t("home.t9") }}</span>
						</div>
					</div>
					<div class="tbody" v-if="rewardRecord.length > 0">
						<div class="tr" v-for="item in rewardRecord" :key="item.id">
							<div>{{ $utils.handleAmount(item.usdt_amount, 4, 2) }}</div>
							<div>{{ $utils.handleAmount(item.gbtc_amount, 4, 2) }}</div>
							<div style="letter-spacing: -0.5px;">{{ $utils.handleTime(item.timestamp) }}</div>
						</div>
					</div>
					<div v-else class="none">
						<img src="../assets/no_record.png" alt="" />
						<!-- 暂无数据 -->
						<div>{{ $t("home.t10") }}</div>
					</div>
				</div>

				<div class="btn_box">
					<!-- 知道了 -->
					<van-button class="cancel_default_btn" @click="show_reward_record = false">{{ $t("home.t11") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 团队奖励明细 -->
		<van-dialog v-model="show_team_reward_record" width="88%" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_table_box">
				<!-- 团队奖励明细 -->
				<div class="title">{{ $t("home.t12") }}</div>
				<div class="subtotal">
					<div class="th">
						<div>
							<!-- 类型 -->
							<span>{{ $t("home.t13") }}</span>
						</div>
						<div>
							<!-- 数量 -->
							<span>{{ $t("home.t14") }}(USDT)</span>
						</div>
						<div>
							<!-- 日期 -->
							<span>{{ $t("home.t15") }}</span>
						</div>
					</div>
					<div class="tbody" v-if="teamRewardRecord.length > 0">
						<div class="tr" v-for="item in teamRewardRecord" :key="item.id">
							<div>{{ item.type == 1 ? $t("home.t16") : $t("home.t17") }}</div>
							<div>{{ $utils.handleAmount(item.amount) }}</div>
							<div style="letter-spacing: -0.5px;">{{ $utils.handleTime(item.timestamp) }}</div>
						</div>
					</div>
					<div v-else class="none">
						<img src="../assets/no_record.png" alt="" />
						<!-- 暫無數據 -->
						<div>{{ $t("home.t10") }}</div>
					</div>
				</div>

				<div class="btn_box">
					<!-- 知道了 -->
					<van-button class="cancel_default_btn" @click="show_team_reward_record = false">{{ $t("home.t11") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- GBTC币说明 -->
		<van-dialog v-model="show_gbtc_info" width="88%" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_table_box">
				<!-- GBTC币 -->
				<div class="title">{{ $t("home.t18") }}</div>
				<div class="rule">
					<!-- ① 发行总量1000万枚； -->
					<div>{{ $t("home.t19") }}</div>
					<!-- ② 参与投资后每24小时释放一次奖励，每次释放总算力的2%； -->
					<div>{{ $t("home.t20") }}</div>
					<!-- ③ GBTC币卖税0.5%，买税100%。 -->
					<div>{{ $t("home.t21") }}</div>
				</div>
				<div class="address_box">
					<div>{{ $t("home.t22") }}{{ $utils.handleAddress($store.state.config.gbtcTokenAddress, 14, 4) }}</div>
					<img src="../assets/copy.png" class="copy" @click="copy($store.state.config.gbtcTokenAddress)"/>
				</div>

				<div class="btn_box">
					<!-- 知道了 -->
					<van-button class="confirm_default_btn" @click="add_Token">添加代币</van-button>
					<van-button class="cancel_default_btn" @click="show_gbtc_info = false">{{ $t("home.t11") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 释放计划明细 -->
		<van-dialog v-model="showRelease" width="88%" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_table_box">
				<div>
					<!-- 释放计划 -->
					<div class="title2">釋放計劃</div>
					<!-- 待釋放 -->
					<div class="subTitle">（待釋放：{{ $utils.handleAmount(releasePlan.remainingFund,4,2) }}）</div>
				</div>
				<div class="subtotal">
					<div class="th">
						<div>
							<!-- 日期 -->
							<span>{{ $t("home.t23") }}</span>
						</div>
						<div>
							<!-- 剩餘 -->
							<span>{{ $t("home.t24") }}</span>
						</div>
						<div>
							<!-- 狀態 -->
							<span>{{ $t("home.t25") }}</span>
						</div>
						<div>
							<!-- 順序 -->
							<span>{{ $t("home.t26") }}</span>
						</div>
					</div>
					<div class="tbody" v-if="releasePlan.funds.length > 0">
						<div class="tr" v-for="(item,index) in releasePlan.funds" :key="index">
							<div style="letter-spacing: -0.5px;">{{ $utils.handleTime(Number(releasePlan.lastUpdateTime)+(index*releasePlan.gapTime)) }}</div>
							<div>{{ $utils.handleAmount(item, 4, 2) }}</div>
							<div>
								<template v-if="Number(releasePlan.receiveFundDay)>index">
									<!-- 已释放 -->
									<span v-if="item>0" class="release">{{ $t("home.t27") }}</span>
									<!-- 提前釋放 -->
									<span v-else class="adv_release">{{ $t("home.t29") }}</span>
								</template>
								<!-- 待釋放 -->
								<span v-else-if="item>0" class="unrelease">{{ $t("home.t28") }}</span>
								<!-- 提前釋放 -->
								<span v-else class="adv_release">{{ $t("home.t29") }}</span>
							</div>
							<!-- 第几天 -->
							<div>{{ $t("home.t30", {day: index+1}) }}</div>
						</div>
					</div>
					<div v-else class="none">
						<img src="../assets/no_record.png" alt="" />
						<!-- 暂无数据 -->
						<div>{{ $t("home.t10") }}</div>
					</div>
				</div>
				<div class="btn_box">
					<!-- 知道了 -->
					<van-button class="cancel_default_btn" @click="showRelease = false">{{ $t("home.t11") }}</van-button>
				</div>
			</div>
		</van-dialog>
		<!-- 玩法說明 -->
		<van-dialog v-model="showRule" width="88%" :close-on-click-overlay="true" :show-confirm-button="false">
			<div class="dialog_table_box">
				<!-- 玩法說明 -->
				<div class="title" style="line-height: 45px;padding-top: 12px;">{{ $t("home.t31") }}</div>
				<div style="font-size: 12px;color: #999;margin-top: 10px;line-height: 24px;max-height: 40vh;overflow: hidden auto;display: flex;flex-direction: column;gap: 6px;">
					<div v-for="(r, index) in $t('rule')" :key="index">{{ r }}</div>
					<!-- <div>单次最低投资100USDT，最大获取1.5倍收益，单设备每人初始最大投资份额10次共1,000U，每推荐一人投资+1次投资份额</div>
					<div>投资获得1.5倍算力和1.5倍卖币额度，每日按照金本位3%释放，共计50天释放完毕</div>
					<div>收益组成部分：静态收益+直推奖+二二滑落网体奖励</div>
					<div>静态收益：每日释放的算力，点击领取后会从底池自动帮您兑换成等值的GBTC代币，您可在DAPP中直接出售或转入代币合约自动出售</div>
					<div>直推奖：您推荐的直属下级投资或复投金额的20%将从底池兑换成等值数量的GBTC代币</div>
					<div>二二滑落网体奖励：用户投资或复投时，依次从投资人开始向上寻找二二滑落网体的有效上级，形成一个最大20人名单均分投资金额的20%USDT直接转到钱包账户</div>
					<div>有效上级说明：投资人在您以下20层内且未超出您的有效范围（有效范围=初始12层，每直推一人投资+1层）并且您的算力和总可用额度充足</div>
					<div>团队奖励和直推奖励将从释放计划尾部依次抵扣</div>
					<div>如您的算力已释放或抵扣完毕，奖励将会于购买GBTC销毁(烧伤)</div>
					<div>出售代币时将扣除卖币额度</div>
					<div>二二滑落网体奖励将扣除卖币额度，如未释放则从释放计划尾部依次抵扣</div> -->
				</div>

				<div class="btn_box">
					<!-- 知道了 -->
					<van-button class="cancel_default_btn" @click="showRule = false">{{ $t("home.t11") }}</van-button>
				</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import { ethers } from 'ethers';
import { Notify } from "vant-green";
import Index from "../components/IndexView.vue";
import Swap from "../components/SwapView.vue";
import BindSuperior from "../components/BindSuperior.vue";
import JoinMining from "../components/JoinMining.vue";
import { addToken } from "@/api/connectWallet";
export default {
	components: {
		Index,
		Swap,
		BindSuperior,
		JoinMining,
	},
	data() {
		return {
			account: "",
			config: {},
			active: 0, //导航栏下标
			showBindSuperior: false,
			tabRef: ["index", "swap", "chat"],
			keys: [0, 0, 0],
			refreshLoading: false,
			showJoinMining: false, //参与投资
			show_gbtc_info: false, //gbtc说明
			show_reward_record: false, //奖励记录
			rewardRecord: [],
			show_team_reward_record: false, //团队奖励记录
			teamRewardRecord: [],

			gapTime:86400,
			releasePowerPlan:[],
			showRelease:false,
			releasePlan:{
				funds:[],
				gapTime:0,
				lastUpdateTime:0,
				remainingFund:0,
				receivePowerDay:0,
			},
			showRule:false
		};
	},

	async mounted() {
		this.account = localStorage.getItem("userAccount");
		this.config = this.$store.state.config;
	},

	methods: {
		onChangeTabBar(index) {
			this.$emit("changeTab", index)
			if(index==2){
				this.refreshLoading = false;
				return
			}
			// console.log("onChangeTabBar", index);
			this.keys[index] += 1;
			this.$store.state.tabRefIndex = index;
			window.scrollTo({
				top: 0,
				left: 0,
				// behavior: "smooth",
			});
			this.refreshLoading = true;
			setTimeout(() => {
				this.$refs[this.tabRef[index]].onRefresh(() => {
					this.refreshLoading = false;
				});
			}, 500);
		},
		async onRefresh() {
			console.log("onRefresh");
			this.$refs[this.tabRef[this.active]].onRefresh(() => {
				this.refreshLoading = false;
			});
		},
		async getRewardRecord() {
			this.$toast.loading();
			//查询奖励记录
			try {
				//查询邀请列表
				const result = await this.$api.request(
					"/powerRewardList",
					{
						address: localStorage.getItem("userAccount"),
					},
					"POST"
				);
				if (result.code == 200) {
					this.rewardRecord = result.data;
					// console.log(this.rewardRecord);
					this.show_reward_record = true;
				} else {
					this.rewardRecord = [];
					Notify({
						type: "danger",
						message: result.msg,
					});
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.$toast.clear();
			}
		},
		async getTeamRewardRecord() {
			this.$toast.loading();
			//查询奖励记录
			try {
				//查询邀请列表
				const result = await this.$api.request(
					"/inviteRewardList",
					{
						address: localStorage.getItem("userAccount"),
					},
					"POST"
				);
				if (result.code == 200) {
					this.teamRewardRecord = result.data;
					// console.log(this.teamRewardRecord);
					this.show_team_reward_record = true;
				} else {
					this.teamRewardRecord = [];
					Notify({
						type: "danger",
						message: result.msg,
					});
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.$toast.clear();
			}
		},
		async showReleasePlan(op=1) {
			try {
				this.$toast.loading();
				this.releasePlan =  await new this.$MyContract().call("mine", "releasePlan", [localStorage.getItem("userAccount"), op]);
				console.log(this.releasePlan);
				this.showRelease = true;
			} catch (error) {
				console.log(error);
			} finally {
				this.$toast.clear();
			}
		},

		copy(txt) {
			//复制邀请链接
			this.$copyText(txt).then(() => {
				this.$toast("Copied successfully");
			});
		},
		async add_Token(){
			try {
				const wasAdded = await window.ethereum.request({
					method: 'wallet_watchAsset',
					params: {
						type: 'ERC20',
						options: {
							address: this.$store.state.config.gbtcTokenAddress,
							symbol: "GBTC",
							decimals: 18,
							image: window.location.origin + "/gbtc_logo.png",
						},
					},
				});
				return wasAdded;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.container {
	padding-top: 50px;
	padding-bottom: calc(env(safe-area-inset-bottom) + 10px);
	background-size: 100%;
	background-position-y: 50px;
	min-height: calc(100vh - 150px);
	// background: url('../assets/banner.png') no-repeat;
	// background-position: left 50px;
	// background-size: 100% auto;
}
.banner_bj {
	width: 100%;
	max-width: 500px;
	position: fixed;
	top: 50px;
	z-index: 0;
}

.content {
	min-height: calc(100vh - 100px - env(safe-area-inset-bottom));
}

.dialog_table_box {
	min-height: 300px;
	height: fit-content;
	background-image: linear-gradient(0deg, #262623 0%, #33332f 100%);
	padding: 0 20px 20px;

	.title {
		font-family: PingFang-SC-Bold;
		font-size: 20px;
		color: #ffffff;
		line-height: 77px;
		text-align: center;
	}
	.title2{
		font-family: PingFang-SC-Bold;
		font-size: 20px;
		color: #ffffff;
		text-align: center;
		padding-top: 24px;
	}
	.subTitle{
		font-family: PingFang-SC-Bold;
		font-size: 14px;
		color: #ffffff;
		text-align: center;
		margin-top: 4px;
		padding-bottom: 10px;
	}
	.rule {
		font-family: PingFang-SC-Medium;
		font-size: 13px;
		line-height: 21px;
		color: #b3aba1;
		> div.title {
			font-family: PingFang-SC-Bold;
			font-size: 14px;
			color: #ffffff;
			margin-bottom: 10px;
		}
	}
	.address_box {
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		height: 44px;
		background-color: #383834;
		border-radius: 5px;
		font-family: PingFang-SC-Bold;
		font-size: 14px;
		color: #ffffff;
		.copy {
			width: 17px;
			height: 17px;
		}
	}

	.btn_box {
		margin-top: 37px;
		display: flex;
		justify-content: center;
		gap: 20px;
		.cancel_default_btn {
			flex: 1;
			font-family: PingFang-SC-Bold;
			font-size: 16px !important;
			color: #e0bf8f !important;
		}
		.confirm_default_btn{
			flex: 1;
			background-image: linear-gradient(0deg, #eacea1 0%, #d4aa77 100%);
			border-radius: 8px !important;
			border: none !important;
			font-size: 16px !important;
		}
	}
}

.subtotal {
	margin-top: 16px;
	background: none;
	border-radius: 6px;
	overflow: hidden;
	min-height: 200px;
	max-height: 500px;
	.tbody {
		max-height: calc(100vh - 500px);
		overflow: hidden;
		overflow-y: auto;
		min-height: 300px;
	}
	.tr,
	.th {
		display: flex;
		gap: 0 4px;
		justify-content: space-between;
		font-family: PingFang SC;
		font-weight: 400;
		font-size: 14px;
		min-height: 36px;
		padding: 2px 0;
		align-items: center;
		text-align: center;

		> div {
			flex: 3;
		}
		// > div:nth-child(1) {
		// 	flex: 4;
		// }
	}
	.th {
		background: #383834;
		> div > span {
			font-size: 14px;
			color: #b3aba1;
		}
	}
	.tr {
		font-family: PingFang-SC-Medium;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #000;
	}

	.tr:nth-child(odd) {
		background: #30302c;
		color: #b3aba1;
	}
	.tr:nth-child(even) {
		background: #383834;
		color: #b3aba1;
	}

	.none {
		display: flex;
		flex-direction: column;
		height: fit-content;
		justify-content: center;
		align-items: center;
		margin-top: 40px;
		user-select: none;
		color: #999;
		padding-bottom: 40px;
		img {
			width: 120px;
			margin-bottom: 10px;
		}
	}
	.level_img {
		height: 16px;
		vertical-align: middle;
	}
	.released{
		color: #4CAF50;
	}
	.unrelease{
		color: #2196F3;
	}
	.adv_release{
		color:#FFC107
	}
}
</style>
