import Web3 from 'web3';
import CryptoJS from 'crypto-js';
const Decimal = require('decimal.js');
export function numToK(value, max = 9, min = 0) {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
    return noExponents(formatter.format(value));
}
export function handleAmount(value, max = 9, min = 0, unit='ether') {
    var amount = 0;
    if(value>0){
        amount = Web3.utils.fromWei(value.toString(), unit);
    }
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
    return noExponents(formatter.format(amount));
}
export function toFixed(value,max=0) {
    if(!value) return 0;
    let amount = Web3.utils.fromWei(value, 'ether');
    const factor = Math.pow(10, max);
    return Math.floor(amount * factor) / factor;
}
export const noExponents = (exponent) => {
    const data = String(exponent).split(/[eE]/);
    if (data.length === 1) return data[0];
    let z = '', sign = exponent < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;
    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
};

export function int2hex(num, width) {
    /* 转换 */
    var hex = '0123456789abcdef'
    var s = ''
    while (num) {
        s = hex.charAt(num % 16) + s; num = Math.floor(num / 16)
    }
    if (typeof width === 'undefined' || width <= s.length) {
        return '0x' + s
    }
    var delta = width - s.length
    var padding = ''
    while (delta-- > 0) {
        padding += '0'
    }
    return '0x' + padding + s
}

export function idComplex(id, length) {
    return CryptoJS.MD5(id).toString().slice(0, length) + '00' + id;
}

//地址是否相同
export function isSameAddress(address1, address2) {
    return address1 === address2 || String(address1).toLowerCase() === String(address2).toLowerCase();
}
export function handleAddress(address,start = 6, end = 4) {
    return address.slice(0, start) + "..." + address.slice(-end);
}
export function handleTime(time, format = 'YYYY-MM-DD HH:mm:ss') {
    //转为YYYY-MM-DD HH:mm:ss
    let date = new Date(time * 1000);
    let Y = date.getFullYear();
    let M = date.getMonth() + 1;
    let D = date.getDate();
    let h = date.getHours();
    let i = date.getMinutes();
    let s = date.getSeconds();

    if (M < 10) {
        M = '0' + M
    }
    if (D < 10) {
        D = '0' + D
    }
    if (h < 10) {
        h = '0' + h
    }
    if (i < 10) {
        i = '0' + i
    }
    if (s < 10) {
        s = '0' + s
    }
    return Y + '-' + M + '-' + D + ' ' + h + ':' + i + ':' + s;
}

export function formatTime(time, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!time) return '';

    time += '000';
    // 如果 time 是时间戳，确保转换为数字并处理可能的秒级/毫秒级差异
    if (typeof time === 'string' || typeof time === 'number') {
        time = new Date(Number(time));
        if (isNaN(time.getTime())) {
            return ''; // 无效时间戳
        }
    }

    // 确保 time 是有效的 Date 对象
    if (!(time instanceof Date) || isNaN(time.getTime())) {
        return '';
    }

    // 定义替换规则
    const map = {
        'YYYY': time.getFullYear(),
        'MM': String(time.getMonth() + 1).padStart(2, '0'),
        'DD': String(time.getDate()).padStart(2, '0'),
        'HH': String(time.getHours()).padStart(2, '0'),
        'mm': String(time.getMinutes()).padStart(2, '0'),
        'ss': String(time.getSeconds()).padStart(2, '0')
    };

    // 替换格式中的占位符
    return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (match) => map[match]);
}


//把数字转为ether
export function toWei(num) {
    return num?Web3.utils.toWei(num, 'ether'):0;
}
//把ether转为数字
export function fromWei(num) {
    return num?Web3.utils.fromWei(num, 'ether'):0;
}
//把数字转bigNumber
export function toBN(num) {
    return num?Web3.utils.toBigInt(num):0;
}
//睡眠
export function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}
/**
 * 比较两个数的大小
 * @param {string|number} value1 - 第一个值，可以是字符串或数字。
 * @param {string|number} value2 - 第二个值，可以是字符串或数字。
 * @param {string} comparisonType - 比较类型，可以是 'gt > ', 'gte >=', 'lt <', 'lte <= ', 'eq =='。
 * @returns {boolean} - 返回比较结果，true 或 false。
 */
export function compareValues(value1, value2, comparisonType = 'gt') {
    const web3 = new Web3();
    console.log(web3)
    // 转换为 BN 对象
    const bn1 = Web3.utils.toBigInt(value1.toString());
    const bn2 = Web3.utils.toBigInt(value2.toString());
    console.log(bn1,bn2)

    // 根据比较类型返回结果
    switch (comparisonType) {
        case 'gt':
            return bn1 > bn2;
        case 'gte':
            return bn1 >= bn2;
        case 'lt':
            return bn1 < bn2;
        case 'lte':
            return bn1 <= bn2;
        case 'eq':
            return bn1 === bn2;
        default:
            throw new Error('Invalid comparison type. Use gt, gte, lt, lte, or eq.');
    }
}

export function computer(value1, value2, op = 'add'){
    return new Decimal(value1)[op](value2);
}