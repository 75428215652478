<template>
	<div class="content_box">
		<div class="box box_Gradient">
			<div class="my_account">
				<!-- 邀请链接 -->
				<div class="lebel">{{ $t("index.t2") }}</div>
				<div class="value">{{ dappInfo.superior != $store.state.address0 || this.$store.state.isTop ? inviteLink : "-" }}</div>
			</div>
			<div class="btn_box">
				<!-- 复制邀请链接 -->
				<van-button @click="copy" class="copyLinkBtn" v-if="dappInfo.superior != $store.state.address0 || this.$store.state.isTop">{{ $t("index.t3") }}</van-button>
				<!-- 换绑邀请人 -->
				<van-button @click="showBindSuperior" class="changeBindBtn" v-else>{{ $t("index.t4") }}</van-button>
				<van-button @click="$emit('rule')" class="changeBindBtn">玩法說明</van-button>
			</div>
			<!-- 私募成功后，邀请人不可更换 -->
			<div class="red" v-if="!(dappInfo.superior != $store.state.address0 || this.$store.state.isTop)">*{{ $t("index.t5") }}</div>
		</div>
		<div class="box">
			<div class="title">
				<span>GBTC</span>
				<img src="../assets/i2.png" class="info" @click="$emit('shoeGBTCInfo')" />
				<div class="mining_number">{{ $t("index.t31") }}<span class="num">{{ dappInfo.miningNum }}</span></div>
			</div>
			<!-- <div class="remaining">
				<!== 余额 ==>
				<div class="title">{{ $t("index.t6") }}</div>
				<span class="value">{{ $utils.handleAmount(dappInfo.gbtcBalance, 5, 2) }} GBTC</span>
				<div class="value_usdt"> ≈ {{ $utils.handleAmount(gbtcPriceValue, 5, 2) }} USDT</div>
			</div> -->
			<div class="remaining">
				<div style="padding: 0 15px 20px;">
					<van-button class="default_btn" @click="joinMining">
						<!-- 参与投资 -->
						<span>{{ $t("index.t7") }}</span>
					</van-button>
				</div>
				<span class="value">{{ $utils.handleAmount(dappInfo.gbtcBalance, 2, 1) }} GBTC</span>
				<div class="value_usdt"><span class="releseFund">可用额度</span> {{ $utils.handleAmount(dappInfo.releseFund, 2, 1) }} USDT</div>
			</div>
			<!-- <div class="btn_box">
				<van-button class="default_btn" @click="joinMining">
					<!== 参与投资 ==>
					<span>{{ $t("index.t7") }}</span>
				</van-button>
			</div> -->
			<div class="line"></div>
			<div class="title">
				<!-- 投资 -->
				<div>
					<div style="font-size: 16px;">{{ $t("index.t8") }}</div>
					<!-- 每日{{ getTime }}释放奖励 -->
					<div class="getTime" v-if="dappInfo.waitReleasePower>0 || dappInfo.waitReleaseFund>0">{{ $t("index.t32") + getTime + $t("index.t33") }}</div>
				</div>
				
				<van-count-down v-if="dappInfo.waitReleasePower>0 || dappInfo.waitReleaseFund>0" :time="time" class="time" @finish="getUserInfo()">
					<template #default="timeData">
						<span class="block">{{ timeData.hours<10?'0'+timeData.hours:timeData.hours }}</span>
						<span class="colon">:</span>
						<span class="block">{{ timeData.minutes<10?"0"+timeData.minutes:timeData.minutes }}</span>
						<span class="colon">:</span>
						<span class="block">{{ timeData.seconds<10?"0"+timeData.seconds:timeData.seconds }}</span>
					</template>
				</van-count-down>
				<div class="time getTime" v-else>{{ $t("index.t34") }}</div>
			</div>
			<div class="value_box">
				<div>
					<div>
						<!-- 待释放算力 -->
						<!-- <div>{{ $t("index.t9") }}<img v-if="dappInfo.newVersion" src="../assets/i2.png" class="info" @click="$emit('releasePower')" /></div> -->
						<div>{{ $t("index.t9") }}</div>
						<div>{{ $utils.handleAmount(dappInfo.waitReleasePower, 5, 2) }}</div>
					</div>
					<div>
						<!-- 待释放额度 -->
						<!-- <div>{{ $t("index.t9_1") }}<img v-if="dappInfo.newVersion" src="../assets/i2.png" class="info" @click="$emit('releaseFund')" /></div> -->
						<div>{{ $t("index.t9_1") }}</div>
						<div>{{ $utils.handleAmount(dappInfo.waitReleaseFund, 5, 2) }}</div>
					</div>
				</div>
				<div>
					<div>
						<div>
							<!-- 可领取 -->
							<span>{{ $t("index.t11") }}(GBTC)</span>
							<img src="../assets/i2.png" class="info" @click="$emit('showRewardRecord')" />
						</div>
						<div> ≈ {{ $utils.handleAmount(dappInfo.canReceiveToGbtc, 5, 2) }}</div>
					</div>
				</div>
			</div>
			<!-- 价值 -->
			<!-- <div class="value_usdt">{{ $t("index.t12") }} ≈ {{ $utils.handleAmount(dappInfo.canReceive, 5, 2) }} GBTC</div> -->
			<div class="value_usdt">价值 ≈ {{ $utils.handleAmount(dappInfo.canReceivePower, 5, 2) }} USDT</div>
			<div class="btn_box">
				<van-button class="default_btn" @click="claimReward" :loading="claimRewardLoading" :loading-text="claimRewardLoadingTXT" :disabled="dappInfo.canReceivePower <= 0">
					<!-- 领取 -->
					<span>{{ $t("index.t16") }}</span>
				</van-button>
			</div>
			<div class="rule">
				<!-- ① 每24小时可领取一次； -->
				<div>{{ $t("index.t13") }}</div>
				<!-- ② 未领取的GBTC可累积领取； -->
				<div>{{ $t("index.t14") }}</div>
				<!-- ③ 领取奖励扣除对应的算力和能量。 -->
				<div>{{ $t("index.t15") }}</div>
			</div>
		</div>
		<div class="box">
			<div class="title">
				<!-- 团队奖励 -->
				<span>{{ $t("index.t17") }}</span>
				<img src="../assets/i2.png" class="info" @click="$emit('showTeamRewardRecord')" />
			</div>
			<div class="remaining">
				<!-- 已获取 -->
				<div class="title">{{ $t("index.t18") }}</div>
				<span class="value">{{ $utils.handleAmount(dappInfo.teamReward, 5, 2) }}<span class="unit">USDT</span></span>
			</div>

			<div class="rule">
				<!-- ① 团队奖励自动到账； -->
				<div>{{ $t("index.t19") }}</div>
				<!-- ② 团队奖励扣除对应的算力和能量； -->
				<div>{{ $t("index.t20") }}</div>
				<!-- ③ 用户投资时40%的U将奖励给团队。 -->
				<div>{{ $t("index.t21") }}</div>
			</div>
		</div>
		<div class="box no_bj">
			<div class="switch_box">
				<!-- 直推 -->
				<div :class="{ active: active == 0 }" @click="active = 0">{{ $t("index.t22") }}({{ inviteData.invited_number }})</div>
				<!-- 团队 -->
				<div :class="{ active: active == 1 }" @click="active = 1">{{ $t("index.t23") }}({{ inviteData.team_headcount }})</div>
			</div>
			<!-- 邀請列表 -->
			<van-tabs v-model="active" animated>
				<van-tab>
					<div class="subtotal">
						<div class="th">
							<!-- 直推账号 -->
							<div>
								<span>{{ $t("index.t24") }}</span>
							</div>
							<!-- 直推人数 -->
							<div>
								<span>{{ $t("index.t22") }}{{ $t("index.t25") }}</span>
							</div>
							<!-- 团队人数 -->
							<div>
								<span>{{ $t("index.t23") }}{{ $t("index.t25") }}</span>
							</div>
						</div>
						<div class="tbody" v-if="inviteData.invitedList.length > 0">
							<div class="tr" v-for="item in inviteData.invitedList" :key="item.id">
								<div>{{ $utils.handleAddress(item.account) }}</div>
								<div>{{ item.invited_number }}</div>
								<div>{{ item.team_headcount }}</div>
							</div>
						</div>
						<div v-else class="none">
							<img src="../assets/no_record.png" alt="" />
							<!-- 暂无数据 -->
							<div>{{ $t("index.t26") }}</div>
						</div>
					</div>
				</van-tab>
				<van-tab>
					<div class="subtotal">
						<div class="th">
							<!-- 团队账号 -->
							<div>
								<span>{{ $t("index.t27") }}</span>
							</div>
							<!-- 直推人数 -->
							<div>
								<span>{{ $t("index.t22") }}{{ $t("index.t28") }}</span>
							</div>
							<!-- 团队人数 -->
							<div>
								<span>{{ $t("index.t23") }}{{ $t("index.t28") }}</span>
							</div>
						</div>
						<div class="tbody" v-if="inviteData.teamList.length > 0">
							<div class="tr" v-for="item in inviteData.teamList" :key="item.id">
								<div>{{ $utils.handleAddress(item.account) }}</div>
								<div>{{ item.invited_number }}</div>
								<div>{{ Number(item.team_headcount) + 1 }}</div>
							</div>
						</div>
						<div v-else class="none">
							<img src="../assets/no_record.png" alt="" />
							<!-- 暂无数据 -->
							<div>{{ $t("index.t26") }}</div>
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>

		<!-- <van-button class="default_btn" @click="getTestUsdt">
			<span>领取测试USDT,每次1万</span>
		</van-button> -->
	</div>
</template>

<script>
import { Notify } from "vant-green";
export default {
	name: "MarketView",
	props: {},
	data() {
		return {
			dappInfo: {
				miningNum:0,
				gbtcBalance:0,
				releseFund:0,
				joinTime:0,
				countdown:0,
				waitReleasePower:0,
				waitReleaseFund:0,
				canReceivePower:0,
				price:0,
				newVersion:0,
				teamReward:0,
				superior:''
			},
			superiorTop: "", //顶级
			// price: 3e17,		//每份单价（1份10个 1个300U）
			canGetAmount: 0, //可领取数量
			useGetAmount: 0, //已领取数量
			canReceiveToGbtc: 0, //可领取到gbtc
			userAccount: "",
			superior: "",
			inviteLink: "",
			claimRewardLoading: false,
			claimRewardLoadingTXT: "合约执行中...",
			updateLoading: false,
			updateLoadingTXT: "合约执行中...",

			earned: 0,
			u2ct: 0,
			u2ct_invite: 0,
			claimLoading: false,
			claimLoadingTXT: "领取收益中...",
			claimInviteLoading: false,
			claimInviteLoadingTXT: "领取奖励中...",

			active: 0,

			inviteData: {
				invitedList: [],
				teamList: [],
				invited_number: 0,
				team_headcount: 0,
			},

			show_power_reward: false,
			showclaimReward: false, //是否显示参与私募

			showGbtcInfo: false, //是否显示gbtc说明
			time: 0,
			getTime: "00:00:00",

			gbtcPriceValue: 0,
		};
	},
	watch: {
		//监听 this.$store.state.superior
		"$store.state.changeSuperior": function (newVal, oldVal) {
			this.superior = this.$store.state.superior;
		},
		"$store.state.changeJoin": function (newVal, oldVal) {
			this.getUserInfo();
		},
	},
	async mounted() {
		console.log("mounted");
		this.userAccount = localStorage.getItem("userAccount");
		this.superior = this.$store.state.superior;
		this.superiorTop = this.$store.state.superiorTop;
		//获取邀请链接(url+参数)
		this.inviteLink = window.location.origin + "/#/?superior=" + localStorage.getItem("userAccount");
		//查询用户信息
		this.getUserInfo();
	},
	methods: {
		async onRefresh(callback) {
			this.getUserInfo();
			setTimeout(() => {
				callback();
			}, 300);
		},
		async getUserInfo() {
			try {
				const dappInfo = await new this.$MyContract().call("mine", "getDappInfo", [localStorage.getItem("userAccount")]);
				console.log(dappInfo);
				//转为时分秒
				this.getTime = this.$utils.formatTime(dappInfo.joinTime, "HH:mm:ss");
				this.time = (dappInfo.countdown*1000).toString();
				this.dappInfo = dappInfo;

				this.$store.state.dappInfo = this.dappInfo;

				
				// if (this.dappInfo.canReceivePower > 0) {
				// 	this.canReceiveToGbtc = await new this.$MyContract().call("mine", "getAmountOut", [this.$store.state.config.usdtTokenAddress, this.$store.state.config.gbtcTokenAddress, this.dappInfo.canReceivePower.toString()]);
				// } else {
				// 	this.canReceiveToGbtc = 0;
				// }
				// if(this.dappInfo.gbtcBalance > 0){
				// 	//计算多少U
				// 	this.gbtcPriceValue = await new this.$MyContract().call("mine", "getAmountOut", [this.$store.state.config.gbtcTokenAddress, this.$store.state.config.usdtTokenAddress, this.dappInfo.gbtcBalance.toString()]);
				// }else{
				// 	this.gbtcPriceValue = 0;
				// }
				if (this.dappInfo.newVersion > 0 || this.$utils.isSameAddress(this.userAccount, this.superiorTop)) {
					//查询邀请列表
					const result = await this.$api.request(
						"/invitedList",
						{
							address: localStorage.getItem("userAccount"),
						},
						"POST"
					);
					if (result.code == 200) {
						this.inviteData = result.data;
					} else {
						this.inviteData = {
							invitedList: [],
							teamList: [],
							invited_number: 0,
							team_headcount: 0,
						};
						Notify({
							type: "danger",
							message: result.msg,
						});
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		showBindSuperior() {
			this.$emit("bindSuperior");
		},
		async joinMining() {
			this.$emit("joinMining");
		},
		copy() {
			//复制邀请链接
			this.$copyText(this.inviteLink).then(() => {
				this.$toast("Copied successfully");
			});
		},
		//领取奖励
		async claimReward() {
			try {
				this.claimRewardLoading = true;
				const result = await new this.$MyContract().send("mine", "claimReward", []);
				// console.log(result);
				if (result && result.hash) {
					Notify({
						type: "success",
						message: this.$t("index.t29"), //"领取成功",
					});
					await this.getUserInfo();
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.claimRewardLoading = false;
			}
		},

		//查询用户信息
		async getUsers() {
			try {
				const contract = new this.$MineContract(this.$store.state.config.mine);
				return await contract.getUsers();
			} catch (error) {
				console.log(error);
			}
		},

		//查询用户上级信息
		async getSuperior() {
			try {
				return await new this.$MyContract().call("mine", "teams", [localStorage.getItem("userAccount")]);
			} catch (error) {
				console.log(error);
				throw error;
			}
		},
		showPowerReward() {
			this.$emit("showPowerReward");
		},
		showInviteReward() {
			this.$emit("showInviteReward");
		},
		// async getTestUsdt() {
		// 	try {
		// 		//loading
		// 		this.$toast.loading({
		// 			message: "Loading...",
		// 			duration: 0,
		// 		});
		// 		await new this.$MyContract().call("usdt", "mint", []);
		// 	} catch (error) {
		// 		console.log(error);
		// 	} finally {
		// 		this.$toast.clear();
		// 	}
		// },
		async usdt_to_gbtc(amount) {
			console.log(this.$store.state.config.usdtTokenAddress, this.$store.state.config.gbtcTokenAddress, amount.toString());
			const amountOut = await new this.$MyContract().call("mine", "getAmountOut", [this.$store.state.config.usdtTokenAddress, this.$store.state.config.gbtcTokenAddress, amount.toString()]);
			return this.$utils.handleAmount(amountOut, 5, 2);
		},
	},
};
</script>

<style scoped lang="scss">
.content_box {
	margin-top: 20px;
	padding-bottom: 100px;
	.box {
		padding: 15px 15px 20px;
		// background-color: #1a1a18;
		background-color: #1a1a18dd;
		border-radius: 10px;
		margin-top: 10px;
	}
	.box_Gradient {
		background-image: linear-gradient(90deg, #efd98d 0%, #e1995d 100%);
		opacity: 0.95;
	}
	.my_account {
		position: relative;
		background-image: linear-gradient(90deg, #ffe57f 0%, #ffd3b2 100%);
		border-radius: 8px;
		padding: 15px;
		margin-bottom: 10px;

		.label {
			font-size: 14px;
			color: #0d0b08;
		}
		.value {
			margin-top: 4px;
			font-size: 12px;
			color: #0d0b08;
			// white-space: nowrap;
			// overflow: hidden;
			// text-overflow: ellipsis;
			white-space: normal;
			word-break: break-all;
		}
	}
	.btn_box {
		margin-top: 15px;
		display: flex;
		align-items: center;
		gap: 15px;
	}
	.copyLinkBtn {
		border-radius: 8px !important;
		height: 44px !important;
		font-size: 14px !important;
		border: none;
		color: #e0bf8f;
		background-color: #1a1a18 !important;
		width: 100%;
	}
	.changeBindBtn {
		border-radius: 8px !important;
		height: 44px !important;
		font-size: 14px !important;
		border: solid 1px #1a1a18;
		background: none;
		width: 100%;
	}
	.mining_number{
		margin-left: auto;
		font-size: 14px;
		>.num{
			color: #e0bf8f;

		}
	}
	.title {
		color: #fff;
		display: flex;
		align-items: center;
		// justify-content: space-between;
		font-size: 18px;
		line-height: 1;
		padding-bottom: 14px;
		.info {
			width: 13px;
			height: 13px;
			margin-left: 5px;
			margin-top: -8px;
		}
		.time{
			margin-left: auto;
			color: #fff;
			.colon {
				display: inline-block;
				margin: 0 4px;
				color: #efd98d;
			}
			.block {
				display: inline-block;
				width: 22px;
				color: #fff;
				font-size: 12px;
				text-align: center;
				background-color: #e1995d;
				border-radius: 4px;
			}
		}
		.getTime{
			font-size: 12px;
			color: #efd98d;
			margin-left: auto;
		}
	}
	.remaining {
		border-radius: 5px;
		border: solid 1px #e0bf8f;
		position: relative;
		text-align: center;
		display: flex;
		flex-direction: column;
		padding: 30px 0 19px 0;
		.title {
			position: absolute;
			left: 10px;
			top: 10px;
			font-size: 12px;
			color: #e0bf8f;
		}
		.value {
			font-size: 30px;
			color: #e0bf8f;
			margin: auto;
			.unit {
				font-family: PingFang-SC-Medium;
				font-size: 12px;
				color: #e0bf8f;
				margin-left: 5px;
			}
		}
		.value_usdt{
			font-size: 28px;
			margin-top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			
			.releseFund{
				font-size: 20px;
				margin-right: 5px;
			}
		}
	}
	.line {
		height: 2px;
		background: #33332f;
		margin: 15px 0;
	}
	.value_box {
		display: flex;
		flex-direction: column;
		gap: 5px;

		font-family: PingFang-SC-Bold;
		font-size: 24px;
		color: #ffffff;
		line-height: 1;
		.unit {
			font-family: PingFang-SC-Medium;
			font-size: 14px;
			color: #e0bf8f;
			// margin-left: 5px;
		}
		> div {
			display: flex;
			gap: 5px;
		}
		> div > div {
			flex: 1;
			height: 79px;
			background-color: #262623;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 12px;
			> div:first-child {
				font-family: PingFang-SC-Medium;
				font-size: 12px;
				color: #ffffff;
			}
		}
		.info {
			width: 13px;
			height: 13px;
			margin-left: 5px;
			margin-top: -8px;
		}
	}
	.value_usdt {
		margin-top: 10px;
		font-family: PingFang-SC-Medium;
		font-size: 12px;
		color: #e0bf8f;
	}
	.share {
		display: flex;
		justify-content: space-around;
		color: #fff;
		text-align: center;
		align-items: center;
		background-color: #262623;
		border-radius: 5px;
		height: 72px;
		margin-top: 14px;
		> div > div:first-child {
			font-family: PingFang-SC-Medium;
			font-size: 12px;
			color: #ffffff;
		}
		> div > div:last-child {
			font-family: PingFang-SC-Bold;
			font-size: 18px;
			color: #ffffff;
			margin-top: 4px;
		}
		.special {
			color: #e0bf8f !important;
		}
	}
	.rule {
		margin-top: 15px;
		font-family: PingFang-SC-Medium;
		font-size: 11px;
		line-height: 18px;
		color: #998d7d;
	}
	.no_bj {
		background: none;
		padding: 15px 0;
	}

	.switch_box {
		display: flex;
		font-size: 16px;
		line-height: 40px;
		overflow: hidden;
		border-bottom: 1px solid #403628;
		> div {
			color: #b3aba1;
			transition: 300ms;
			flex: 1;
			text-align: center;
			border-bottom: 2px solid #0c0b08;
			&.active {
				color: #e0bf8f;
				border-bottom: 2px solid #e0bf8f;
			}
		}
	}
	.subtotal {
		margin-top: 16px;
		background: none;
		border-radius: 6px;
		overflow: hidden;
		min-height: 200px;
		.tbody {
			height: calc(100% - 60px);
			overflow: hidden;
			overflow-y: auto;
		}
		.tr,
		.th {
			display: flex;
			gap: 0 4px;
			justify-content: space-between;
			font-family: PingFang SC;
			font-weight: 400;
			font-size: 14px;
			min-height: 36px;
			padding: 2px 0;
			align-items: center;
			text-align: center;

			> div {
				flex: 3;
			}
			> div:nth-child(1) {
				flex: 4;
			}
		}
		.th {
			background: #1a1a18;
			> div > span {
				font-size: 14px;
				color: #b3aba1;
			}
		}
		.tr {
			font-family: PingFang-SC-Medium;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			line-height: 18px;
			letter-spacing: 0px;
			color: #000;
		}

		.tr:nth-child(odd) {
			background: #14110d;
			color: #b3aba1;
		}
		.tr:nth-child(even) {
			background: #1a1a18;
			color: #b3aba1;
		}

		.none {
			display: flex;
			flex-direction: column;
			height: fit-content;
			justify-content: center;
			align-items: center;
			margin-top: 40px;
			user-select: none;
			color: #999;
			padding-bottom: 40px;
			img {
				width: 120px;
				margin-bottom: 10px;
			}
		}
		.level_img {
			height: 16px;
			vertical-align: middle;
		}
	}

	.red {
		font-size: 10px;
		color: #ff0000;
		margin-top: 5px;
		text-align: right;
	}
}

.inviteBox {
	height: fit-content;
	background-image: linear-gradient(0deg, #262623 0%, #33332f 100%);
	padding: 15px;
	.title {
		text-align: center;
		margin-top: 10px;
		margin-bottom: 30px;
		font-size: 20px;
		font-weight: bold;
		color: #fff;
	}
	.tip {
		font-size: 12px;
		color: #999;
		margin-top: 10px;
	}

	.btn_box {
		display: flex;
		gap: 20px;
		margin: 50px 0 10px;
	}
}
.loading_box {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 20px;
	span {
		margin-left: 4px;
		font-size: 12px;
		color: #999;
	}
}

.topAddress {
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	margin-top: 20px;
}
</style>
